<template>
<!--  菜单管理-->
    <div class="menuManagement">
      <a-spin :indicator="indicator" :spinning="spinning">
      <div class="select_Info">
        <div>
          名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 210px" size="default" />
          <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchChange">重置</a-button>
        </div>
      </div>
      <div class="table_Info">
        <a-button type="primary" @click="addMenu">新增一级菜单</a-button>

        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :pagination="false" rowKey="id">
        <span slot="icon" slot-scope="item">
          <img :src="item.icon" alt="" v-if="item.icon" style="height: 50px;width: 50px">
        </span>
          <span slot="operate" slot-scope="text,item,index">
          <a style="margin-right: 10px" @click="addSubMenu(item.id)">新增子菜单</a>
            <a style="margin-right: 10px" @click="menuUp(item.id)">上移</a>
            <a  style="margin-right: 10px" @click="menuDown(item.id)">下移</a>
          <a style="margin-right: 10px" @click="editMenu(item.id,item)">修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delMenu(item.id)"
              @cancel="cancel"
          >
            <a >删除</a>
            </a-popconfirm>
      </span>

          <a-table  :pagination="false"
                    :columns="menuColumns"
                    :showHeader="false"
                    slot="expandedRowRender" slot-scope="row"
                    :dataSource="row.zmenu" :rowKey="item => item.id"
                    :locale="{emptyText: '该类别 暂无节点'}"
          >
          <span slot="operate" slot-scope="text,item,index">
             <a v-if="index !== 0" style="margin-right: 10px" @click="menuUp(item.id)">上移</a>
            <a  v-if="index !== row.zmenu.length -1 " style="margin-right: 10px" @click="menuDown(item.id)">下移</a>
          <a style="margin-right: 10px" @click="editSubMenu(item.id,item)">修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delSubMenu(item.id)"
              @cancel="cancel"
          >
            <a >删除</a>
            </a-popconfirm>
      </span>
          </a-table>
        </a-table>
        <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="tableChange" />
        </div>
      </div>

      <a-drawer
          title="新增"
          width="500"
          :visible="addShow"
          @close="()=>{this.addShow = false}"
      >
        <a-form-model  :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem">
          <a-form-model-item label="名称">
            <a-input v-model="addForm.name" />
          </a-form-model-item>
          <a-form-model-item label="页面地址">
            <a-input v-model="addForm.path" />
          </a-form-model-item>
          <a-form-model-item v-show="submenu" label="图标">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 90%;">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadIcon" ref="icon" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="addForm.icon"
                    style="width: 95px;height:95px;"
                    :src="addForm.icon"
                />
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="修改"
          width="500"
          :visible="editShow"
          @close="()=>{this.editShow = false}"
      >
        <a-form-model  :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem">
          <a-form-model-item label="名称">
            <a-input v-model="editForm.name" />
          </a-form-model-item>
          <a-form-model-item label="页面地址">
            <a-input v-model="editForm.path" />
          </a-form-model-item>
          <a-form-model-item v-show="submenu" label="图标">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 90%;">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="editIcon" ref="editIcon" />
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.icon"
                    style="width: 95px;height:95px;"
                    :src="editForm.icon"
                />
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>
      </a-spin>
    </div>


</template>

<script>
import {delMenu, getMenu, postMenu, postSubMenu, putMenu, putMenuDown, putMenuUp} from "@/service/menuManagement_api"
import {update} from "@/utils/update";
import { Logger } from 'sass';

export default {
  name: "menuManagement",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      submenu:true,
      addShow:false,
      editShow:false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      searchName:'',
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      addForm:{
        icon:'',
      },
      editForm:{},
      columns:[
        {
          title: '菜单名称',
          dataIndex: 'name',
        },
        {
          title: '页面地址',
          dataIndex: 'path',
        },
        {
          title: '图标',
          scopedSlots: { customRender: 'icon' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },

      ],
      menuColumns:[
        {
          title: '名称',
          dataIndex: 'name',
          width:'25%'
        },
        {
          title: '地址',
          dataIndex: 'path',
          width:'20%'
        },
        {
          title: 'icon',
          width:'20%'
        },
        {
          title: 'edit',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[
      ],
      menuId:'',
      editMenuId:'',
      menuEnd:0,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"菜单管理")
  },
  mounted() {
    this.getMenus()
  },
  methods:{
    //获取菜单列表
    async getMenus(pageNum,name){
      this.spinning = true
      const info = await getMenu(pageNum,name)
      console.log(info);
      this.InfoTable = info.data.menu
      this.menuEnd = info.data.menu.length

        
      this.pagination.total = info.data.count
      this.spinning = false
    },
    searchOk(){
      this.getMenus(1,this.searchName)
      this.pagination.current = 1
    },
    searchChange(){
      this.getMenus()
      this.searchName = ''
    },
    //分页跳转
    tableChange(e){
      this.getMenus(e)
    },
    //上传一级菜单图标
    async uploadIcon(){
      let inputDOM = this.$refs.icon.files[0];
      const pic = inputDOM.size / 1024 < 500;
      if (!pic){
        this.$message.warning('上传图片大小不能超过500k')
      }else {
        const image =await update(inputDOM)
        if (image.code === 0){
          this.addForm.icon = image.data.url
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
    },
    //修改菜单icon
    async editIcon(){
      let inputDOM = this.$refs.editIcon.files[0];
      const pic = inputDOM.size / 1024 < 500;
      if (!pic){
        this.$message.warning('上传图片大小不能超过500k')
      }else {
        this.$message.success('上传成功')
        const image =await update(inputDOM)
        if (image.code === 0){
          this.editForm.icon = image.data.url
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
    },
    addMenu(){
      this.addShow = true
      this.submenu = true
    },
    editMenu(e,row){
      // this.UpdateForm = record
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editShow =true
      this.submenu = true
      this.editMenuId = e
    },
    addSubMenu(e){
      this.addShow = true
      this.submenu = false
      this.menuId = e
      this.addForm = {}
    },
    editSubMenu(e,row){
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editShow = true
      this.submenu = false
      this.editMenuId = e
    },
    //确认新增
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if (valid){
          if (this.submenu === true){
            const add = await postMenu(this.addForm)
            if (add.code===0){
              this.$message.success('添加成功')
              //刷新列表
              await this.getMenus(this.pagination.current)
              //清空输入框
              this.$refs.addItem.resetFields();
              this.addForm = {}
              //关闭对话框
              this.addShow= false;
            }}
          else {
            const Form = this.addForm
            const data = {
              parentId : this.menuId,
              name : Form.name,
              path: Form.path
            }
            const add = await postSubMenu(data)
            if (add.code===0){
              this.$message.success('添加成功')
              //刷新列表
              await this.getMenus(this.pagination.current)
              //清空输入框
              this.$refs.addItem.resetFields();
              this.addForm = {}
              //关闭对话框
              this.addShow= false;
            }
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //确认修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if (valid){
          if (this.submenu === true){
            const Form = this.editForm
            const data = {
              id : this.editMenuId,
              name : Form.name,
              path: Form.path,
              icon: Form.icon
            }
            const edit = await putMenu(data)
            if (edit.code===0){
              this.$message.success('修改成功')
              //刷新列表
              await this.getMenus(this.pagination.current)
              //清空输入框
              this.$refs.editItem.resetFields();
              //关闭对话框
              this.editShow= false;
            }}
          else {
            const Form = this.editForm
            const data = {
              id : this.editMenuId,
              name : Form.name,
              path: Form.path,
            }
            const edit = await putMenu(data)
            if (edit.code===0){
              this.$message.success('修改成功')
              //刷新列表
              await this.getMenus(this.pagination.current)
              //清空输入框
              this.$refs.editItem.resetFields();
              //关闭对话框
              this.editShow= false;
            }
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //菜单列表上下移动
    menuUp(id){
      putMenuUp(id)
      this.timer = setTimeout(()=>{
        this.getMenus(this.pagination.current)
      },1000);
    },
    menuDown(id){
      putMenuDown(id)
      this.timer = setTimeout(()=>{
        this.getMenus(this.pagination.current)
      },1000);
    },
    //删除菜单
    async delMenu(id){
      const del = await delMenu(id)
      if (del.code === 0 ){
        this.$message.success('删除成功')
        await this.getMenus(this.pagination.current)
      }
      else {
        this.$message.error('删除失败')
      }

    },
    //删除菜单
    async delSubMenu(id){
      const del = await delMenu(id)
      if (del.code === 0 ){
        this.$message.success('删除成功')
        await this.getMenus(this.pagination.current)
      }
      else {
        this.$message.error('删除失败')
      }

    },
    cancel(){
      this.$message.warning('取消删除');
    },
  },

}
</script>

<style lang="scss" scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;

  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.menuManagement{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;
  }
}
</style>
